.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 2rem; /* Footer height */
  padding-bottom: 0.25rem;
  .footer__mobetize-icon {
    height: 2.6875em;
    background-repeat: no-repeat;
  }
}
