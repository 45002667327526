// $breakpoint-small-mobile: 374px;
// $breakpoint-mobile: 572px;
// $breakpoint-tablet: 768px;
// $breakpoint-desktop: 1199px;
// $button-radius-normal: 0em;
// $navigation-button-radius: 0.25em;
// $button-height: calc(1.5em + 0.5rem + 2px);
// $input-radius: 0em;
// $input-color: #000000;
// $custom-radio-radius: 0.25em;
// $custom-radio-box-shadow: 1px 1px 3px #c5c7cb, 0px 1px 6px #f1f3f9;
// $custom-radio-box-shadow-inset: inset 5px 5px 6px #1e262d,
//   inset -5px -5px 6px #445665;
// $drawer-custom-radio-box-shadow: 1px 1px 3px #706f6f, 0 1px 2px #5e5656;
// $drawer-custom-radio-box-shadow-inset: 3px 3px 4px #1e262d inset,
//   -3px -3px 3px #445665 inset;
// $drawer-content-container-box-shadow: 2px 2px 4px #73737d inset,
//   -2px -2px 4px #6e6e7d inset;
// $input-height: calc(1.75em + 0.75rem + 2px);
// $input-padding: 1.5em 1em;
// $border: 1px solid;
// $border-bottom: 1px dashed;

/* Mainstreet specific white labelling items */
$breakpoint-small-mobile: 374px;
$breakpoint-mobile: 572px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1199px;
$button-radius-normal: 1em;
$navigation-button-radius: 0.25em;
$button-height: calc(1.5em + 0.5rem + 2px);
$input-radius: 0.625em;
$input-color: #404041;
$custom-radio-radius: 0.625em;
$custom-radio-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
$custom-radio-box-shadow-inset: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px inset;
$drawer-custom-radio-box-shadow: 1px 1px 2px #e8e8e8, 2px 2px 4px #dadada;
$drawer-custom-radio-box-shadow-inset: 3px 3px 4px #1e262d inset,
  -3px -3px 3px #445665 inset;
$drawer-content-container-box-shadow: 2px 2px 4px #73737d inset,
  -2px -2px 4px #6e6e7d inset;
$input-height: calc(1.75em + 0.75rem + 2px);
$input-padding: 1.5em 1em;
$border: 1px solid;
$border-bottom: 1px dashed;
