@import "./Styles//color.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.outline {
  border-color: $secondary-color;
  font-size: 0.875em;
}

// CHRIS NOTE:  USING SCALING FONTS IS ONLY GOOD IF YOU ARE DEFINING FONTS IN PIXELS! ...
// A MOBILE SCREEN HAS 3x THE AMOUNT OF PIXELS IN A GIVEN AREA; THEREFORE, IF YOU USED PIXELS AS YOUR FONT DEFINITION, YOU'D NEED TO SCALE FOR SCREEN AREA...
// THIS IS WHY WE USE EMs... IT ALREADY ACCOUNTS FOR SCREEN PIXEL DENSITIES AND ALLOWS USERS TO CHANGE TEXT SIZE WITHOUT IMPACTING LAYOUT OR FUNCTIONALITY
// LEAVE THIS SCALING COMMENTED OUT FOR NOW PLEASE.

// @media only screen and (max-width: 1024px) {
//   body {
//     font-size: 0.75em;
//   }
// }

// @media only screen and (min-width: 1024px) and (max-width: 1199px) {
//   body {
//     font-size: 0.75em;
//   }
// }

// @media only screen and (min-width: 1200px) and (min-width: 1365px) {
//   body {
//     font-size: 0.875em;
//   }
// }

// @media only screen and (min-width: 1366px) and (max-width: 1439px) {
//   body {
//     font-size: 1em;
//   }
// }

// @media only screen and (min-width: 1440px) and (max-width: 1679px) {
//   body {
//     font-size: 1em;
//   }
// }

// @media only screen and (min-width: 1680px) and (max-width: 1919px) {
//   body {
//     font-size: 1em;
//   }
// }

// @media only screen and (min-width: 1920px) and (max-width: 2303px) {
//   body {
//     font-size: 1.125em;
//   }
// }

// @media only screen and (min-width: 2304px) and (max-width: 2559px) {
//   body {
//     font-size: 1.25em;
//   }
// }

// @media only screen and (min-width: 2560px) and (max-width: 4096px) {
//   body {
//     font-size: 1.375em;
//   }
// }

div#basic-typeahead-single {
  background-color: white;
}
