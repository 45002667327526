@import "../../../Styles/color.scss";

.backdrop-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 4rem;
  background-color: $backdrop-color;
  z-index: 1020;
}
