// @font-face {
//   font-family: "Helvetica";
//   src: url("../Resources/Fonts/Helvetica.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Light";
//   src: url("../Resources/Fonts/HelveticaNeue-Light.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Bold";
//   src: url("../Resources/Fonts/HelveticaNeue-Bold.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Medium";
//   src: url("../Resources/Fonts/HelveticaNeue-Medium.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Thin";
//   src: url("../Resources/Fonts/HelveticaNeue-Thin.ttf");
// }
// .FontRegular {
//   font-family: Helvetica, Arial, sans-serif;
// }
// .FontLight {
//   font-family: "HelveticaNeue-Light", Arial, sans-serif;
// }
// .FontBold {
//   font-family: "HelveticaNeue-Bold", "Arial Bold", sans-serif;
// }
// .FontMedium {
//   font-family: "HelveticaNeue-Medium", "Arial Bold", sans-serif;
// }
// .FontThin {
//   font-family: "HelveticaNeue-Thin", Arial, sans-serif;
// }
// .FontIcon {
//   font-family: "Font Awesome 5 Free";
// }
// .FontBrand {
//   font-family: "Font Awesome 5 Brands";
// }
// $font-size-ultra-small: 0.5625em;
// $font-size-extra-small: 0.625em;
// $font-size-small: 0.82em;
// $font-size-medium: 1em;
// $font-size-large: 1.5em;
// $font-size-larger: 2em;
// $font-size-super-large: 3em;

/* Mainstreet specific white labelling items */
// @font-face {
//   font-family: "Helvetica";
//   src: url("../Resources/Fonts/Helvetica.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Light";
//   src: url("../Resources/Fonts/HelveticaNeue-Light.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Bold";
//   src: url("../Resources/Fonts/HelveticaNeue-Bold.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Medium";
//   src: url("../Resources/Fonts/HelveticaNeue-Medium.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Thin";
//   src: url("../Resources/Fonts/HelveticaNeue-Thin.ttf");
// }
// @font-face {
//   font-family: "HelveticaNeue-Thin";
//   src: url("../Resources/Fonts/HelveticaNeue-Thin.ttf");
// }

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;700&family=Open+Sans&display=swap");

.FontRegular {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.FontLight {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
}
.FontBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.FontMedium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.FontThin {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
}
.FontIcon {
  font-family: "Font Awesome 5 Free";
}
.FontBrand {
  font-family: "Font Awesome 5 Brands";
}
$font-size-ultra-small: 0.5625em;
$font-size-extra-small: 0.625em;
$font-size-small: 0.82em;
$font-size-medium: 1em;
$font-size-large: 1.5em;
$font-size-larger: 2em;
$font-size-super-large: 3em;

/* SMARTVERIFY SPECIFIC FONTS GO HERE!!! */

/* Community Savings Branding */
@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"),
    url("../Resources/Fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"),
    url("../Resources/Fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"),
    url("../Resources/Fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Light"),
    url("../Resources/Fonts/MYRIADPRO-LIGHT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold"),
    url("../Resources/Fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold Italic"),
    url("../Resources/Fonts/MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed"),
    url("../Resources/Fonts/MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold"),
    url("../Resources/Fonts/MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Italic"),
    url("../Resources/Fonts/MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed Italic"),
    url("../Resources/Fonts/MYRIADPRO-BOLDCONDIT.woff") format("woff");
}

/* Aria Savings Branding */
@font-face {
  font-family: "Gimlet Display";
  font-style: normal;
  font-weight: bold;
  src: local("Gimlet Display Bold"),
    url("../Resources/Fonts/GimletDisplay-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Articulat Demi-Bold";
  font-style: normal;
  font-weight: 600;
  src: local("Articulat Demi-Bold"),
    url("../Resources/Fonts/ArticulatCF-DemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Articulat Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Articulat Regular"),
    url("../Resources/Fonts/ArticulatCF-Regular.otf") format("opentype");
}

/* Anxin Savings - English Branding */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../Resources/Fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../Resources/Fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../Resources/Fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../Resources/Fonts/Roboto-Bold.ttf") format("truetype");
}

/* Anxin Savings Branding - Chinese Simplified */
@font-face {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 300; // Light
  src: url("../Resources/Fonts/苹方黑体-极细-简.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400; // Regular
  src: url("../Resources/Fonts/苹方黑体-准-简.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500; // Medium
  src: url("../Resources/Fonts/苹方黑体-中粗-简.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600; // SemiBold
  src: url("../Resources/Fonts/苹方黑体-中黑-简.ttf") format("truetype");
}

/* NotoSans CJK Fallbacks */
@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 300; // Light
  src: url("../Resources/Fonts/NotoSansSC-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 400; // Regular
  src: url("../Resources/Fonts/NotoSansSC-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 500; // Medium
  src: url("../Resources/Fonts/NotoSansSC-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 600; // SemiBold
  src: url("../Resources/Fonts/NotoSansSC-SemiBold.ttf") format("truetype");
}

/* Anxin Savings Branding - Chinese Traditional */
@font-face {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 300;
  src: url("../Resources/Fonts/苹方黑体-极细-繁.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 400;
  src: url("../Resources/Fonts/苹方黑体-准-繁.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 500;
  src: url("../Resources/Fonts/苹方黑体-中粗-繁.ttf") format("truetype");
}

@font-face {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 600;
  src: url("../Resources/Fonts/苹方黑体-中黑-繁.ttf") format("truetype");
}

/* NotoSans CJK Fallbacks */
@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 300;
  src: url("../Resources/Fonts/NotoSansSC-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 400;
  src: url("../Resources/Fonts/NotoSansSC-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 500;
  src: url("../Resources/Fonts/NotoSansSC-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 600;
  src: url("../Resources/Fonts/NotoSansSC-SemiBold.ttf") format("truetype");
}
