@import "../../Styles/styleConstants.scss";

.vault-dashboard-container {
  .vault-dashboard-container__banner-container {
    height: 9em;
    .info-container {
      height: 5em;
    }
  }
}
